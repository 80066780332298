import { useState } from 'react';

export default function useTextSlice(text?: string) {
  const [showAll, setShowAll] = useState<boolean>(false);

  const max = 250; // Characters
  const isTextOverflooded = text?.length > max;
  const textSlice = text?.slice(0, max);

  const result = showAll || !isTextOverflooded ? text : `${textSlice} [...]`;

  return [result, setShowAll, showAll, isTextOverflooded] as [
    typeof result,
    typeof setShowAll,
    typeof showAll,
    typeof isTextOverflooded,
  ];
}
