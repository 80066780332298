export enum ELotStatus {
     NO_LOT = '0',
     LOT_REQUESTED = '1',
     LOT_CREATED = '2',
     AUCTION_ACTIVE = '3',
     AUCTION_RESOLVED = '4',
     AUCTION_RESOLVED_AND_CLAIMED = '5',
     AUCTION_CANCELED = '6',
}

export enum ESaleType {
     AUCTION = '0',
     DIRECT_SALE = '1',
}

export enum EAuctionTypes {
     FIRST_PRICE = '0x1',
     TIMED_EDITION = '0x2',
     TIMED_EDITION_BUY_LIMIT = '0x3',
     FIRST_PRICE_V2 = '0x4',
     TIMED_EDITION_V2 = '0x5',
     TIMED_EDITION_BUY_LIMIT_V2 = '0x6',
     DIRECT_SALE_V2 = '0x7',
     TIMED_EDITION_MOONPAY = '0x8',
}

export enum EAuctionTypesNoHex {
     FIRST_PRICE = '1',
     TIMED_EDITION = '2',
     TIMED_EDITION_BUY_LIMIT = '3',
     FIRST_PRICE_V2 = '4',
     TIMED_EDITION_V2 = '5',
     TIMED_EDITION_BUY_LIMIT_V2 = '6',
     DIRECT_SALE_V2 = '7',
     TIMED_EDITION_MOONPAY = '8',
}

export enum EAuctionTypesString {
     firstPrice = 'firstPrice',
     timedEdition = 'timedEdition',
     timedEditionBuyLimit = 'timedEditionBuyLimit',
}