import Blockies, { IdenticonProps } from 'react-blockies';
import styled from 'styled-components';
import classes from './Avatar.module.scss';
import { useUsers } from '~/store/userContext/hooks';
import Image from 'next/image';
import { iLotBuyer, iUserProfile, NFTOwner } from '~/store/services/mito/mito.models';
import { STATIC_HOST } from '~/constants';
import { VerificationCheck } from '~/components/_ui-kit/Icons';

export interface AvatarProps extends IdenticonProps {
  personalizedAvatar?: any;
  user?: Partial<NFTOwner | iUserProfile | iLotBuyer>;
  userAddress?: string;
  isNftCollector?: boolean;
  requireVerification?: boolean;
  imageQuality?: number;
  width?: number;
  height?: number;
}

const Avatar = (props: AvatarProps) => {
  const {
    personalizedAvatar,
    user,
    seed = 'Anonymous',
    isNftCollector = false,
    requireVerification = false,
    imageQuality = 20,
  } = props;

  const { getUser } = useUsers();
  let avatarUrl = undefined;
  const isColor = user?.avatarUrl?.type === 'color' || user?.avatarUrl?.value.includes('#');
  const isUserVerified = getUser(seed).verified || user?.verified;
  const artistAvatar = personalizedAvatar || getUser(seed).avatar;
  const userAvatar =
    (!isColor && user?.avatarUrl?.value && `${STATIC_HOST}/${avatarUrl?.value}`) || user?.avatar;

  return (
    <ImageContainer
      className={`${isNftCollector ? classes.nftCollector : classes.regularAvatar}`}
      {...props}
    >
      {userAvatar || artistAvatar ? (
        <Image
          decoding="async"
          placeholder="blur"
          blurDataURL={artistAvatar || userAvatar}
          src={artistAvatar || userAvatar}
          quality={imageQuality}
          width={100}
          height={100}
        />
      ) : (
        <Blockies seed={user?.publicAddress?.toLowerCase() || seed?.toLowerCase()} />
      )}
      {isUserVerified && requireVerification && <VerificationCheck className="verificationCheck" />}
    </ImageContainer>
  );
};

export default Avatar;

/** Styled components */

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  position: relative;

  svg.verificationCheck {
    position: absolute;
    z-index: 9;
    bottom: -4px;
    right: -4px;
    width: 16px;
    height: 16px;

    background-color: var(--theme-grey1);
    border-radius: 100%;
  }

  img,
  canvas {
    border-radius: 50%;
    width: 100% !important;
    height: 100% !important;
  }
`;

const CustomBlockies = styled(Blockies)`
  border-radius: 50%;
`;
