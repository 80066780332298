import React from 'react';
import classes from './MediaControlButton.module.scss';

interface Props {
  Icon: (props: React.SVGProps<SVGElement>) => React.ReactElement;
  onClick: any;
  className?: string;
}

export default function MediaControlButton({ Icon, onClick, className = '' }: Props) {
  return (
    <button onClick={onClick} className={`${classes.MediaControlButton} ${className}`}>
      <Icon className={classes.icon} />
    </button>
  );
}
