import { useState, useEffect } from 'react';
import Image, { ImageProps } from 'next/image';
import dynamic from 'next/dynamic';
import classes from './ImageWithFallback.module.scss';
import { isVideoExtension } from '~/utils';
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

const ImageWithFallback = (props) => {
  // TODO: refactor with unique Json for filters
  const {
    src,
    fallbackSrc,
    stopPlaying,
    rewardFilter,
    collectionFilter,
    upcomingFilter,
    key,
    imageQuality = 50,
    ...rest
  } = props;
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    if (src !== imgSrc) {
      setImgSrc(src);
    }
  }, [src]);

  if (isVideoExtension(imgSrc)) {
    return (
      <ReactPlayer
        url={imgSrc}
        poster="/placeholder.png"
        className={`${classes.video} ${collectionFilter && classes.collectionFilter} ${
          upcomingFilter && classes.upcomingFilter
        }`}
        onError={() => {
          setImgSrc(fallbackSrc);
        }}
        width="100%"
        height="100%"
        playsinline
        playing={!stopPlaying}
        loop
        autoPlay
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: '1.6rem 1.6rem 0 0',
          opacity: rewardFilter ? 0.4 : 1,
          mixBlendMode: rewardFilter ? 'multiply' : 'normal',
          filter: rewardFilter ? 'grayscale(100%)' : '',
          zIndex: rewardFilter ? '2' : '',
        }}
        muted
        config={{
          file: {
            attributes: { disableRemotePlayback: true },
          },
        }}
      />
    );
  }

  return (
    <Image
      {...rest}
      blurDataURL="data:image/gif;base64,IzI2MjYyNg=="
      placeholder="blur"
      src={imgSrc}
      quality={imageQuality}
      style={{
        opacity: rewardFilter ? 0.4 : 1,
        mixBlendMode: rewardFilter ? 'multiply' : 'normal',
        filter: rewardFilter ? 'grayscale(100%)' : '',
        zIndex: rewardFilter ? '2' : '',
      }}
      unoptimized={process.env.NEXT_PUBLIC_ENVIRONMENT === 'LOCAL'}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
    />
  );
};

export default ImageWithFallback;
