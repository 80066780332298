// prettier-ignore
import { useWeb3React } from '@web3-react/core';
import { useLang } from '~/store/languageContext/hooks';
import { iLot, iUserBlockchainNFT } from '~/store/services/mito/mito.models';
import { getCorrectAuctionType } from '~/utils';
import { EAuctionTypes, ELotStatus } from '~/store/services/mito/mito.enums';

interface HLotInfo {
  lot?: Partial<iLot>;
  userNft?: iUserBlockchainNFT;
  verbose?: boolean;
}

export default function useLotInfo({ lot, verbose, userNft }: HLotInfo) {

  const { account } = useWeb3React();
  const { t } = useLang();
  const userAddress = account?.toLowerCase();

  const nft = lot?.nft;
  const bids = lot?.bids;
  const sales = lot?.sales;
  const hasBids = bids?.length > 0;
  const now = Date.now();
  const startTime = Number(lot?.start_time) * 1000;
  const endTime = Number(lot?.end_time) * 1000;
  const mintedLots = lot?.minted
  const lotLimit = lot?.limit

  const edition =
    (lot?.nft?.max_copy_number && lot?.nft?.copy_number) ||
    (userNft?.max_copy_number && userNft?.copy_number) ?
    `${t('Artwork:copy_of_mº')
      .replace('{n}', lot?.nft?.copy_number || userNft?.copy_number)
      .replace('{m}', lot?.nft?.max_copy_number || userNft?.max_copy_number)}` : null

  const isOwnedByUser = lot?.owner?.publicAddress === userAddress;
  const isACopyOwnedByUser = lot?.sales?.some((sale) => sale?.buyer?.publicAddress === userAddress);
  const userOwns = isOwnedByUser || isACopyOwnedByUser;
  const auctionType = lot?.sale_contract?.hex_index || `0x${lot?.sale_contract?.index}`

  const lastSale = sales?.length > 0 ? sales[sales.length - 1] : null;

  // TODO: Check contract for timed edition buy limit V1/V2
  const copies = auctionType === EAuctionTypes.TIMED_EDITION
      || auctionType === EAuctionTypes.TIMED_EDITION_V2
      || auctionType === EAuctionTypes.TIMED_EDITION_BUY_LIMIT
      || auctionType === EAuctionTypes.TIMED_EDITION_BUY_LIMIT_V2
      || auctionType === EAuctionTypes.TIMED_EDITION_MOONPAY ? {
    limit: lotLimit,
    sold: mintedLots,
    remaining: lotLimit > mintedLots ? lotLimit - mintedLots : 0,
    lastSale,
    lastSaleEnds: Number(lastSale?.block_timestamp_creation || 0) * 1000,
  } : {}

  const auctionEnded = endTime < now || copies.remaining === 0 || lot?.lot_status === ELotStatus.AUCTION_RESOLVED_AND_CLAIMED;
  const auctionStarted = startTime < now;
  const status = auctionEnded ? 'ended' : startTime <= now && endTime > now ? 'started' : startTime > now ? 'still-start' : null;

  const countdown = (copies.remaining === 0 && copies.lastSaleEnds) || (status === 'still-start' && startTime) || endTime || null;

  const isOpenFirstPrice = !auctionEnded && auctionType === EAuctionTypes.FIRST_PRICE || auctionType === EAuctionTypes.FIRST_PRICE_V2;
  const isOpenTimedEdition = !auctionEnded &&
  ( auctionType === EAuctionTypes.TIMED_EDITION
    || auctionType === EAuctionTypes.TIMED_EDITION_V2
    || auctionType === EAuctionTypes.TIMED_EDITION_BUY_LIMIT
    || auctionType === EAuctionTypes.TIMED_EDITION_BUY_LIMIT_V2
    || auctionType === EAuctionTypes.TIMED_EDITION_MOONPAY);

  // TODO: Check contract for timed edition buy limit V1/V2
  const copyNumber = {
    short: `${t('Lot:copies_of_max_short')
      ?.replace('{n}', copies.remaining)
      ?.replace('{m}', copies.limit)}`,
    large: `${t('Lot:copies_of_max_large')
      ?.replace('{n}', copies.limit - copies.remaining + 1)
      ?.replace('{m}', copies.limit)}`,
  };

  const copiesTxt = verbose ? copyNumber.large : copyNumber.short;
  const uniqueTxt = t(verbose ? 'Lot:one_of_one_large' : 'Lot:one_of_one_short')
    ?.replace('{n}', 1)
    ?.replace('{m}', 1);

    const labels = {
      chip:
        (auctionEnded && (auctionType === EAuctionTypes.FIRST_PRICE || auctionType === EAuctionTypes.FIRST_PRICE_V2) && !hasBids && uniqueTxt) ||
        (auctionEnded && t('Lot:sold_out')) ||
        ((auctionType === EAuctionTypes.FIRST_PRICE || auctionType === EAuctionTypes.FIRST_PRICE_V2) && uniqueTxt) ||
        (copies.remaining && copiesTxt) ||
        null,
      countdown:
        (status === 'still-start' && t('Lot:starts')) ||
        (!auctionEnded && (auctionType === EAuctionTypes.FIRST_PRICE || auctionType === EAuctionTypes.FIRST_PRICE_V2) && t('Lot:ends')) ||
        (verbose && auctionEnded && (auctionType === EAuctionTypes.TIMED_EDITION || auctionType === EAuctionTypes.TIMED_EDITION_V2 || auctionType === EAuctionTypes.DIRECT_SALE_V2) && t('Lot:sold_out_on')) ||
        (verbose && auctionEnded && !userOwns && t('Lot:ends_on')) ||
        (verbose && auctionEnded && userOwns && t('Lot:bought_on')) ||
        null,
      countdownValue:
        ((auctionEnded || (auctionType === EAuctionTypes.TIMED_EDITION_V2 || auctionType === EAuctionTypes.TIMED_EDITION)) && userOwns && t('Lot:purchased')) ||
        (auctionEnded && ' ') ||
        null,
      price:
        userNft && !lot && t('Lot:bought_for') ||
        (lot && isOpenFirstPrice && !hasBids && t('Lot:bid_for')) ||
        (lot && isOpenFirstPrice && hasBids && t('Lot:last_bid')) ||
        (lot && isOpenTimedEdition && copies.remaining && t('Lot:buy_for')) ||
        (lot && auctionType === EAuctionTypes.DIRECT_SALE_V2 && !auctionEnded && t('Lot:buy_for')) ||
        (lot && auctionType === EAuctionTypes.FIRST_PRICE && auctionEnded && !hasBids && t('Lot:auction_ended')) ||
        (lot && auctionEnded && !userOwns && t('Lot:sold_for')) ||
        (((auctionEnded && userOwns) || (auctionType === EAuctionTypes.DIRECT_SALE_V2 && auctionEnded)) && t('Lot:bought_for')),
      buyButton:
        (isOpenTimedEdition && userOwns && t('Lot:buy_more')) || ((isOpenTimedEdition || auctionType === EAuctionTypes.DIRECT_SALE_V2) && t('Lot:buy')),
    };

    const userBid = bids?.find((bid) => bid.bidder === account?.toLowerCase());
    const canClaim = auctionEnded && userBid && !userBid.claimed;

    const lotInfo = {
      startTime,
      endTime,
      nft,
      auctionType,
      status,
      copies,
      userOwns,
      countdown,
      labels,
      hasBids,
      userBid,
      canClaim,
      edition,
      auctionEnded,
      isOpenFirstPrice,
      auctionStarted
    };

    return lotInfo;

}
