import React from 'react';
import classes from './BuyButton.module.scss';

interface Props {
  handleClick: any;
  label: string;
  className?: string;
  disabled?: boolean;
}

export default function BuyButton(props: Props) {
  const { className, handleClick, label, disabled = false } = props;
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={`${classes.BuyButton} ${className}`}
    >
      <span className={classes.label}>{label}</span>
    </button>
  );
}
