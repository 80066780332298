import { HTMLAttributes } from 'react';
import theme from '~/styles/theme';

interface Icon extends HTMLAttributes<Element> {
  size?: string;
}

export const MiClose = (props: Icon) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7235 1.53333C14.0707 1.1862 14.0707 0.623382 13.7235 0.276249C13.3764 -0.0708835 12.8136 -0.0708835 12.4665 0.276249L6.99975 5.74295L1.53305 0.276249C1.18592 -0.0708835 0.623107 -0.0708835 0.275975 0.276249C-0.0711582 0.623382 -0.0711582 1.1862 0.275975 1.53333L5.74267 7.00003L0.275975 12.4667C-0.0711582 12.8139 -0.0711582 13.3767 0.275975 13.7238C0.623107 14.0709 1.18592 14.0709 1.53305 13.7238L6.99975 8.25711L12.4665 13.7238C12.8136 14.0709 13.3764 14.0709 13.7235 13.7238C14.0707 13.3767 14.0707 12.8139 13.7235 12.4667L8.25683 7.00003L13.7235 1.53333Z"
      fill="white"
    />
  </svg>
);

export const MiArrowRight = (props: Icon) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.47173 2.86177C8.21138 2.60142 7.78927 2.60142 7.52892 2.86177C7.26857 3.12212 7.26857 3.54423 7.52892 3.80458L11.0575 7.33317H3.33366C2.96547 7.33317 2.66699 7.63165 2.66699 7.99984C2.66699 8.36803 2.96547 8.6665 3.33366 8.6665H11.0575L7.52892 12.1951C7.26857 12.4554 7.26857 12.8776 7.52892 13.1379C7.78927 13.3983 8.21138 13.3983 8.47173 13.1379L13.1379 8.47172C13.1395 8.47011 13.1411 8.46849 13.1427 8.46687C13.2604 8.34703 13.3331 8.18293 13.3337 8.00184C13.3337 8.00117 13.3337 8.0005 13.3337 7.99984C13.3337 7.99917 13.3337 7.9985 13.3337 7.99784C13.3334 7.90817 13.3154 7.82267 13.2831 7.74465C13.2513 7.6678 13.2045 7.5957 13.1427 7.5328C13.1412 7.5312 13.1396 7.52961 13.138 7.52802M13.138 7.52802L8.47173 2.86177L13.138 7.52802Z"
      fill="white"
    />
  </svg>
);

export const MiGradientBell = (props: Icon) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00022 0.666504C6.76254 0.666504 5.57555 1.15817 4.70038 2.03334C3.82521 2.90851 3.33355 4.09549 3.33355 5.33317C3.33355 7.56828 2.8555 8.95626 2.41495 9.76394C2.19375 10.1695 1.97837 10.4355 1.82875 10.5934C1.75376 10.6726 1.69484 10.7251 1.65936 10.7546C1.64161 10.7694 1.62971 10.7785 1.62458 10.7823L1.62267 10.7837C1.38368 10.9479 1.27792 11.2482 1.36215 11.5264C1.44729 11.8075 1.70644 11.9998 2.00021 11.9998H14.0002C14.294 11.9998 14.5531 11.8075 14.6383 11.5264C14.7225 11.2482 14.6167 10.9479 14.3778 10.7837L14.3759 10.7823C14.3707 10.7785 14.3588 10.7694 14.3411 10.7546C14.3056 10.7251 14.2467 10.6726 14.1717 10.5934C14.0221 10.4355 13.8067 10.1695 13.5855 9.76394C13.1449 8.95626 12.6669 7.56828 12.6669 5.33317C12.6669 4.09549 12.1752 2.90851 11.3 2.03334C10.4249 1.15817 9.23789 0.666504 8.00022 0.666504ZM12.415 10.4024C12.4657 10.4954 12.5166 10.5834 12.5673 10.6665H3.4331C3.48382 10.5834 3.53475 10.4954 3.58548 10.4024C4.14493 9.37675 4.66688 7.76473 4.66688 5.33317C4.66688 4.44912 5.01807 3.60127 5.64319 2.97615C6.26831 2.35103 7.11616 1.99984 8.00022 1.99984C8.88427 1.99984 9.73212 2.35103 10.3572 2.97615C10.9824 3.60127 11.3335 4.44912 11.3335 5.33317C11.3335 7.76473 11.8555 9.37675 12.415 10.4024Z"
      fill="url(#MiBell_paint0_linear)"
    />
    <path
      d="M7.42355 13.6653C7.2388 13.3468 6.83085 13.2384 6.51237 13.4232C6.19388 13.6079 6.08547 14.0159 6.27022 14.3344C6.44602 14.6374 6.69837 14.889 7.00199 15.0639C7.30561 15.2388 7.64984 15.3308 8.00022 15.3308C8.35059 15.3308 8.69482 15.2388 8.99844 15.0639C9.30206 14.889 9.55441 14.6374 9.73022 14.3344C9.91496 14.0159 9.80655 13.6079 9.48806 13.4232C9.16958 13.2384 8.76163 13.3468 8.57688 13.6653C8.51828 13.7663 8.43416 13.8502 8.33296 13.9085C8.23175 13.9668 8.11701 13.9975 8.00022 13.9975C7.88342 13.9975 7.76868 13.9668 7.66747 13.9085C7.56627 13.8502 7.48215 13.7663 7.42355 13.6653Z"
      fill="url(#MiBell_paint1_linear)"
    />
    <defs>
      <linearGradient
        id="MiBell_paint0_linear"
        x1="8.00022"
        y1="0.666504"
        x2="8.00022"
        y2="15.3308"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA9579" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="MiBell_paint1_linear"
        x1="8.00022"
        y1="0.666504"
        x2="8.00022"
        y2="15.3308"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA9579" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

export const MiQuestionMark = (props: Icon) => (
  <svg
    width={props.size || '24'}
    height={props.size || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 15H13V17H11V15ZM13 13.355V14H11V12.5C11 12.2348 11.1054 11.9804 11.2929 11.7929C11.4804 11.6054 11.7348 11.5 12 11.5C12.2841 11.5 12.5623 11.4193 12.8023 11.2673C13.0423 11.1154 13.2343 10.8984 13.3558 10.6416C13.4773 10.3848 13.5234 10.0988 13.4887 9.81684C13.454 9.53489 13.34 9.26858 13.1598 9.04891C12.9797 8.82924 12.7409 8.66523 12.4712 8.57597C12.2015 8.48671 11.912 8.47587 11.6364 8.54471C11.3608 8.61354 11.1104 8.75923 10.9144 8.96482C10.7183 9.1704 10.5847 9.42743 10.529 9.706L8.567 9.313C8.68863 8.70508 8.96951 8.14037 9.38092 7.67659C9.79233 7.2128 10.3195 6.86658 10.9086 6.67332C11.4977 6.48006 12.1275 6.44669 12.7337 6.57661C13.3399 6.70654 13.9007 6.99511 14.3588 7.41282C14.8169 7.83054 15.1559 8.36241 15.3411 8.95406C15.5263 9.54572 15.5511 10.1759 15.4129 10.7803C15.2747 11.3847 14.9785 11.9415 14.5545 12.3939C14.1306 12.8462 13.5941 13.1779 13 13.355Z"
      fill="white"
    />
  </svg>
);

export const VerificationCheck = (props: Icon) => (
  <svg viewBox="0 0 16 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM12.4999 7.41423C12.8904 7.0237 12.8904 6.39054 12.4999 6.00001C12.1093 5.60949 11.4762 5.60949 11.0856 6.00001L7.49996 9.58588L5.91423 8.00009C5.52371 7.60957 4.89055 7.60957 4.50002 8.00009C4.1095 8.39062 4.1095 9.02378 4.50002 9.41431L6.08575 11.0001C6.8668 11.7811 8.13313 11.7811 8.91418 11.0001L12.4999 7.41423Z"
      fill="url(#verificationCheck_paint0_linear)"
    />
    <defs>
      <linearGradient
        id="verificationCheck_paint0_linear"
        x1="8"
        y1="0.5"
        x2="8"
        y2="16.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={theme.colors.highlight} />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

export const VerifcationCheckBack = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Z" fill="#202020" /><path fillRule="evenodd" clipRule="evenodd" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm6.749-13.629a1.5 1.5 0 1 0-2.122-2.121l-5.378 5.379L8.87 11.25a1.5 1.5 0 1 0-2.121 2.121l2.378 2.38a3 3 0 0 0 4.243 0l5.379-5.38Z" fill="url(#a)" /><defs><linearGradient id="a" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse"><stop stopColor="#FA9579" /><stop offset="1" stopColor="#fff" /></linearGradient></defs></svg>
);

export const VerifcationCheckBackTiny = () => (
<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Z" fill="#202020"/><path fillRule="evenodd" clipRule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm4.499-9.086A1 1 0 1 0 11.085 5.5L7.499 9.086 5.913 7.5A1 1 0 1 0 4.5 8.914L6.085 10.5a2 2 0 0 0 2.828 0L12.5 6.914Z" fill="url(#a)"/><defs><linearGradient id="a" x1="8" y1="0" x2="8" y2="16" gradientUnits="userSpaceOnUse"><stop stopColor="#FA9579"/><stop offset="1" stopColor="#fff"/></linearGradient></defs></svg>
);

export const VerificationCheckWhite = () => {
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18Zm5.06-10.22a1.13 1.13 0 0 0-1.59-1.6l-4.03 4.04-1.79-1.78a1.13 1.13 0 0 0-1.59 1.59l1.79 1.78c.87.88 2.3.88 3.18 0l4.03-4.03Z" fill="url(#a)"/><defs><linearGradient id="a" x1="9" y1="0" x2="9" y2="18" gradientUnits="userSpaceOnUse"><stop stop-color="#FA9579"/><stop offset="1" stop-color="#fff"/></linearGradient></defs></svg>
}