import dynamic from 'next/dynamic';
import NFTCardN from './NFTCard/NFTCard';
import NFTDetailsCardOwnerN from './NFTDetailsCardOwner/NFTDetailsCardOwner';

const CollectionCardN = dynamic(import('./CollectionCard/CollectionCard'));
const UserCardN = dynamic(import('./UserCard/UserCard'));
const UpcomingCard = dynamic(import('./UpcomingCard/UpcomingCard'));
const ResaleCardN = dynamic(import('./ResaleCard/ResaleCard'));
const UserCollectorCardN = dynamic(import('./NFTCollectorCard/NFTCollectorCard'));
const UserCollectorCard = dynamic(import('./UserCollectorCard/UserCollectorCard.legacy'));
const RewardCard = dynamic(import('./RewardCard/RewardCard'));
const RewardFilterCard = dynamic(import('./RewardFilter/RewardFilterCard'));
const RewardTypeCard = dynamic(import('./RewardTypeCard/RewardTypeCard'));
const NFTDetailsCardN = dynamic(() => import('./NFTDetailsCard/NFTDetailsCard'), { ssr: false });

export {
  CollectionCardN,
  UserCardN,
  UpcomingCard,
  NFTCardN,
  NFTDetailsCardN,
  NFTDetailsCardOwnerN,
  ResaleCardN,
  UserCollectorCardN,
  UserCollectorCard,
  RewardCard,
  RewardFilterCard,
  RewardTypeCard,
};
