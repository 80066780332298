import { IPFS_NODE, STATIC_HOST } from "~/constants";
import { iLotNFT, iUserBlockchainNFT } from "~/store/services/mito/mito.models";

export const parseUserForStatics = (userNft: iUserBlockchainNFT) => {

     return {
          contentType: userNft?.content_type,
          ipfs_url: userNft?.ipfs_url,
          original_nft_contract_id: userNft?.hex_original_nft_contract_id,
          hex_original_nft_contract_id: userNft?.hex_original_nft_contract_id,
        }

}