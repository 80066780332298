import React from 'react';
import classes from './Chip.module.scss';

interface Props {
  text: any;
  className?: string;
  children?: React.ReactNode;
}

export default function Chip({ text = 'text', className, children }: Props) {
  return (
    <div className={`${classes.Chip} ${className}`}>
      {children}
      {text}
    </div>
  );
}
