import { Share } from '~/components/Icons';
import { useViewport } from '~/store/viewportContext/viewportContext';
import classes from './ShareButton.module.scss';

interface Props {
  handleClick: any;
  label?: string;
  className?: string;
  nftVariant?: boolean;
}

export default function ShareButton({ className, handleClick, label, nftVariant }: Props) {
  const { width, breakpoints } = useViewport();

  const variant = nftVariant ? width > breakpoints.tablet : width > breakpoints.small;

  return (
    <button onClick={handleClick} className={`${classes.ShareButton} ${className && className}`}>
      <div className={classes.index}>
        <span className={classes.overlay} />
        <Share className={classes.icon} />
        {label && variant && <span className={classes.label}>{label}</span>}
      </div>
    </button>
  );
}
