import styled from '@emotion/styled';
import React from 'react';
import { Check } from '~/components/Icons';
import classes from './Checkbox.module.scss';

interface Props {
  selected: boolean;
  setSelected: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  label?: string;
}

export default function Checkbox(props: Props) {
  const Button = styled.button`
    width: 24px;
    height: 24px;
    border-radius: 0.4rem;
    border: 1px solid;
    position: relative;
    border-color: ${(props: Props) =>
      props.selected ? '#FA9579' : 'var(--background5)'};
    background-color: ${(props: Props) =>
      props.selected ? '#FA9579' : 'transparent'};
  `;
  return (
    <Button
      {...props}
      onClick={() => props.setSelected(!props.selected)}
      className={`${classes.Checkbox} ${props.className && props.className}`}
    >
      {props.selected && <Check className={classes.icon} />}
      {props.label && <span>{props.label}</span>}
    </Button>
  );
}
