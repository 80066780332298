import React from 'react';
import classes from './ActionButton.module.scss';

interface Props {
  handleClick: any;
  label: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

export default function ActionButton(props: Props) {
  const { className, handleClick, label, disabled = false } = props;
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={`${classes.ActionButton} ${className && className}`}
    >
      <div className={classes.innerContainer}>
        <span className={classes.children}>{props.children}</span>
        <span className={classes.label}>{label}</span>
      </div>
    </button>
  );
}
