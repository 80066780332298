import styled from '@emotion/styled';
import React from 'react';
import classes from './Tab.module.scss';

interface Props {
  selected: boolean;
  setSelected: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  label?: string;
}

function Tab(props: Props) {
  const Button = styled.button`
    border-color: ${(props: Props) =>
      props.selected ? '#FA9579' : 'transparent'};
    background-color: ${(props: Props) =>
      props.selected ? '#303030' : '#303030'};
  `;

  return (
    <Button
      {...props}
      onClick={() => props.setSelected(!props.selected)}
      className={`${classes.Tab} ${props.className && props.className}`}
    >
      {props.label && props.label}
    </Button>
  );
}

export default React.memo(Tab);
