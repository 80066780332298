import React, { PropsWithChildren, useEffect, useState } from 'react';
import { BottomSheet as RSBottomSheet } from 'react-spring-bottom-sheet';
import styled, { css } from 'styled-components';
import useMediaDevice from '~/hooks/useMediaDevice';
import CloseButton from '../CloseButton';
import { BottomSheetStyles } from './styles';

export interface BottomSheetProps {
  isOpen?: boolean;
  overlay?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onStateChange?: (state: { isOpen: boolean }) => void;
  withPaddings?: boolean;
}

const BottomSheet = ({
  overlay,
  children,
  header,
  isOpen,
  footer,
  withPaddings,
  onStateChange,
}: PropsWithChildren<BottomSheetProps>) => {
  const isMobile = useMediaDevice('mobile');
  const [_isOpen, setOpen] = useState(false);

  // Actualiza el estado interno
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const toggleOpen = (value: boolean) => {
    setOpen(value);
    if (onStateChange) onStateChange({ isOpen: value });
  };

  return (
    <>
      <BottomSheetStyles withPaddings={withPaddings} />
      {_isOpen ? (
        children
      ) : (
        <div onClick={() => toggleOpen(true)}>{children}</div>
      )}
      <RSBottomSheet
        open={_isOpen}
        header={
          <SheetHeaderWrapper>
            {header}
            <CloseButtonContainer>
              <CloseButton
                size={isMobile ? 'small' : 'large'}
                onClick={() => toggleOpen(false)}
              />
            </CloseButtonContainer>
          </SheetHeaderWrapper>
        }
        footer={footer}
        scrollLocking
        onDismiss={() => toggleOpen(false)}
        snapPoints={({ minHeight, maxHeight }) => {
          const floorHeight = 253;
          const minAvailableHeight =
            minHeight > floorHeight ? minHeight : floorHeight;
          const customMaxHeight = isMobile ? maxHeight : maxHeight - 40;
          const customMinHeight =
            minHeight >= customMaxHeight ? customMaxHeight : minAvailableHeight;
          return [customMinHeight];
        }}
      >
        <Surface>{overlay}</Surface>
      </RSBottomSheet>
    </>
  );
};

export default BottomSheet;

/** Styled components */

const Surface = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.textColor};
    position: relative;
    line-height: 1.2;
    font-weight: 400;
    font-size: 16px;
    border-radius: 0px;
  `}
`;

const SheetHeaderWrapper = styled.div`
  position: relative;
  min-height: 56px;
  padding-right: 56px;
`;

const CloseButtonContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    ${theme.device.tabletS} {
      padding: 32px;
    }
  `}
`;
