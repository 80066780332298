import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import React, { useState } from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';
import { BuyButton } from '~/components/Buttons';
import CountDownTimer from '~/components/CountDownTimer/CountDownTimer';
import { List } from '~/components/Icons';
import { BidHistoryModalN } from '~/components/Modals';
import { Line } from '~/components/Spinners';
import Alert from '~/components/_ui-kit/Alert';
import useLotInfo from '~/hooks/useLotInfo';
import useLotPrice from '~/hooks/useLotPrice';
import useMediaDevice from '~/hooks/useMediaDevice';
import { BidData, LotData, Status } from '~/pages/nft/nft.adapter';
import { Lot } from '~/store/services/graph/types';
import { logClient, shortenAddress } from '~/utils';
import { getPrice, localePrice } from '~/ether-utils';
import classes from './BidBar.module.scss';
import { useLang } from '~/store/languageContext/hooks';
import { useFiatConversion } from '~/store/fiatConversionContext/hooks';
import { ALLOWED_OWNER_COPIES } from '~/constants';
import { useStore } from '~/store/store';
import { useWallets } from '~/store/walletContext/WalletContext';
import { iLot, iNFTDetails } from '~/store/services/mito/mito.models';
import { EAuctionTypes, ELotStatus } from '~/store/services/mito/mito.enums';

interface Props {
  className?: string;
  nftDetail: iNFTDetails;
  bidStatus: Status;
  claimStatus: Status;
  euroPrice: string;
  lotData: LotData;
  bidData: BidData;
  hasLoaded: boolean;
  setShowPaymentPanel: React.Dispatch<React.SetStateAction<boolean>>;
  refreshData: () => void;
  buy: () => void;
  claim: () => void;
  copiesUserOwned: number;
  lastSoldPrice: any;
}

export default function BidBar({
  className,
  nftDetail,
  bidStatus,
  hasLoaded,
  lotData,
  bidData,
  buy,
  claim,
  lastSoldPrice,
  claimStatus,
  refreshData,
  copiesUserOwned,
  setShowPaymentPanel
}: Props) {

  const isMobile = useMediaDevice('mobile');
  const { setShowWalletModal, showWalletModal } = useWallets();
  const lotPrice = useLotPrice({ directSaleNft: nftDetail });
  const { fiatPrice, maticPrice } = lotPrice;
  const calculateDecimals = lotPrice?.maticPrice < 0 ? 1 : 0
  const { account, active } = useWeb3React();
  const { t, lang } = useLang();
  const { formatFiat } = useFiatConversion();
  const { state } = useStore();
  const { upcoming, hasStarted, highestBid, highestBidder } = lotData;
  const { hasMadeBid, isHighestBidder, bidId, minBidIncreaseAmount } = bidData;
  const fiat = (matic) => matic * state.conversionRate;
  const isLoading = bidStatus === 'loading' || claimStatus === 'loading';
  const hasEnded = Number(nftDetail?.active_lot?.end_time) < Date.now() / 1000;
  const cantBuyCopy = account === nftDetail?.owner?.publicAddress
    || nftDetail?.active_lot?.lot_status !== ELotStatus.AUCTION_ACTIVE


  const ButtonColumn = () => {
    if(cantBuyCopy) return null;
    if (!account) {
      return (
        <BuyButton
          disabled={false}
          className={classes.bidButton}
          handleClick={() => setShowWalletModal(!showWalletModal)}
          label={ t('common:to_buy') }
        />
      );
    } if(!hasEnded && account) {
      return (
          <BuyButton
            disabled={!account && !active}
            className={classes.bidButton}
            handleClick={ () => setShowPaymentPanel(true) }
            label={ t('common:to_buy') }
          />
      );
    }
    return null;
}

  return (
    <>
      <div className={`${classes.BidBar} ${className}`}>
        { isLoading ? (
          <div className={classes.loadingContainer}>
            <h3>{t('Lot:signature_pending')}</h3>
            <Line />
          </div>
        ) : (
          <>
            <div className={`${classes.column} ${classes.left}`}>
            <p className={classes.label}>
              { cantBuyCopy ? t('Lot:bought_for') : t('Lot:buy_for') }
            </p>
              <p className={classes.price}>
                {localePrice(maticPrice?.toString() !== '0' ? maticPrice?.toString() : getPrice(lastSoldPrice),3, calculateDecimals,lang,)}
                {' '}MATIC{' '}
                {formatFiat(localePrice(fiatPrice || fiat(getPrice(lastSoldPrice)), 2, null, lang))}
              </p>
            </div>
            <>
              <ButtonColumn />
            </>
          </>
        )}
      </div>
    </>
  );
}
