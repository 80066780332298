import { useState, FC } from 'react';
import styled, { css } from 'styled-components';
import { IconTime, IconPriceTag, IconAuction } from '~/components/Icons';
import { useStore } from '~/store/store';
import { useLang } from '~/store/languageContext/hooks';
import { daysHoursMinutesString } from '~/utils';
import { AuctionTypes } from '~/store/services/auctions';
import useLotInfo from '~/hooks/useLotInfo';
import { iLot } from '~/store/services/mito/mito.models';

declare interface Props {
  lot: iLot;
}

/**
 * Criterios del componente:
 *
 * 1. Reloj: diferencia de hoy hasta el startDate de la subasta
 * 2. Auction icon: - si no tiene pujas -> Start Date
 *                  - si tiene pujas -> End date -> Habrá fecha exacta (hasta lifetime end)
 * 3: En venta: Si eres el owner y está en venta directa
 */

const StateChip: FC<Partial<Props>> = ({ lot }: Props) => {
  const { t, lang } = useLang();
  const currentLot = useLotInfo({ lot });
  const { nft, labels, countdown } = currentLot;
  const { state } = useStore();

  const isAuction = lot?.sale_contract?.hex_index === AuctionTypes.firstPriceV2;
  const startedAuction = currentLot?.startTime < Date.now();
  const isDirectSale =
    lot?.sale_contract?.hex_index === AuctionTypes.directSaleV2 ||
    lot?.sale_contract?.hex_index === AuctionTypes.timedEditionMoonPay;
  const isOwner = lot?.sale_contract?.owner?.toLowerCase() === state?.userAddress?.toLowerCase();
  const hasBids = lot?.bids?.length > 0;
  const endedAuction = isAuction && currentLot.endTime < Date.now();

  if (endedAuction) return null;

  const getChip = () => {
    if((!hasBids && !startedAuction)) {
      const saleIn = daysHoursMinutesString(currentLot?.startTime)
      if(!saleIn) return null;
      return (
        <>
          <p>{`${t('Sale:in')} ${saleIn}`}</p>
          <IconTime />
        </>
      );
    }
    if (isAuction && startedAuction && hasBids) {
      const endsIn = daysHoursMinutesString(currentLot?.endTime);
      if (!endsIn) return null;
      return (
        <>
          <p>{`${endsIn}`}</p>
          <IconAuction />
        </>
      );
    }
    if (isDirectSale && isOwner) {
      return (
        <>
          <p>{t('Lot:onSale')}</p>
          <IconPriceTag />
        </>
      );
    } else return null;
  };

  const chipInfo = getChip();
  if (!chipInfo) return null;

  return <Root>{chipInfo}</Root>;
};

export default StateChip;

const Root = styled.div`
  ${({ theme }) => css`
    background: white;
    border-radius: 16px;
    position: absolute;
    padding: 8px 12px 8px 12px;
    z-index: 1;
    gap: 8px;
    left: 16px;
    top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      color: #4a4a4a;
      position: relative;
      top: -1px;

      > svg {
        position: relative;
        /* top: 3px; */
        margin-bottom: 4px;
      }
    }
  `}
`;
