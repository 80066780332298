import Link from 'next/link';
import React, { Fragment, useEffect, useState } from 'react';
import Avatar from '~/components/_ui-kit/Avatar/Avatar';
import { ShareButton } from '~/components/Buttons';
import { PolygonIcon } from '~/components/Icons';
import { ShareMenu } from '~/components/Menus';
import Modal from '~/components/_ui-kit/Modal';
import { IPFS_NODE } from '~/constants';
import useLocaleDescription from '~/hooks/useLocaleInfo';
import useTextSlice from '~/hooks/useTextSlice';
import { BidData, LotData, Status } from '~/pages/nft/nft.adapter';
import { Collection, Lot } from '~/store/services/graph/types';
import { useStore } from '~/store/store';
import { useUsers } from '~/store/userContext/hooks';
import { useViewport } from '~/store/viewportContext/viewportContext';
import { getDropSrc, shortenAddress, getNFTEdition } from '~/utils';
import { formatPrice } from '~/ether-utils';
import BidBar from './BidBar/BidBar';
import classes from './NFTDetailsCard.module.scss';
import { useLang } from '~/store/languageContext/hooks';
import { getSameTokensAs } from '~/store/services/graph/graph';
import { useWeb3React } from '@web3-react/core';
import Markdown from 'markdown-to-jsx';
import { chainIDToBlockExplorer } from '~/wallets/connectors';
import typo from '~/styles/typography';
import BottomSheet from '~/components/_ui-kit/BottomSheet';
import useMediaDevice from '~/hooks/useMediaDevice';
import { ExternalLink, IconExclusive } from '~/components/Icons';
import styled, { css } from 'styled-components';
import { toDecimal } from '~/utils';
import { STATIC_HOST } from '~/constants';
import isEmpty from 'lodash/isEmpty';
import { iLot, iNFTDetails } from '~/store/services/mito/mito.models';

interface Props {
  className?: string;
  bidStatus: Status;
  claimStatus: Status;
  bidData: BidData;
  nftDetail: iNFTDetails;
  setShowPaymentPanel: React.Dispatch<React.SetStateAction<boolean>>;
  lotData: any;
  hasLoaded: boolean;
  refreshData: () => void;
  buy: () => void;
  claim: any;
  isAirdrop?: any;
}

export default function NFTDetailsCardOwner({
  className,
  lotData,
  bidData,
  hasLoaded,
  buy,
  claim,
  nftDetail,
  isAirdrop,
  refreshData,
  bidStatus,
  claimStatus,
  setShowPaymentPanel,
}: Props) {
  const { account, active } = useWeb3React();
  const { getUser } = useUsers();
  const { t, lang } = useLang();
  const [showShareMenu, setShowShareMenu] = useState(false);
  const { width } = useViewport();
  const [euroPrice, setEuroPrice] = useState('0.00');
  const [isOpen, setIsOpen] = useState(false);
  const { state } = useStore();
  const [copiesUserOwned, setCopiesUserOwned] = useState<any>(null);

  const isMobile = useMediaDevice('mobile');
  const handleClick = () => setShowShareMenu(!showShareMenu);
  const edition = t('Artwork:copy_of_mº')
    .replace('{n}', nftDetail?.copy_number)
    .replace('{m}', nftDetail?.max_copy_number);
  const realOwner = nftDetail?.active_lot?.owner || nftDetail?.owner;
  const creatorAvatar = getUser(nftDetail?.creator).avatar;

  const [description, showFullDescription, isAllDesc, descOverflooded] = useTextSlice(
    JSON.parse(nftDetail?.description)[lang],
  );
  const descriptionParagraphs = description?.split('\\n')?.filter((p) => p);

  const sales: boolean = nftDetail?.lots?.length > 0;

  const shortAddress = shortenAddress(nftDetail.creator);

  useEffect(() => {
    if (nftDetail?.active_lot?.price) {
      const euro = Number(nftDetail?.active_lot?.price) * state.conversionRate;
      setEuroPrice(formatPrice(euro.toString(), 2, null, lang));
    }
  }, [state.conversionRate]);

  //function used to calculate same nfts quantity for the same owner
  async function fetchData() {
    const { data } = await getSameTokensAs(account, nftDetail?.ipfs_url);
    setCopiesUserOwned(data.data.nfts.length);
  }

  if (active) fetchData();

  const header = (
    <Header>
      <span>{t('NFTDetail:token_info')}</span>
    </Header>
  );

  const modalContent = () => {
    const source = getDropSrc(nftDetail?.ipfs_url);

    return (
      <>
        <Row>
          <p>{t('NFTDetail:contract_address')}</p>
          <Link
            href={`${chainIDToBlockExplorer[process.env.NEXT_PUBLIC_CHAIN_ID]}address/${
              nftDetail?.is_bridged ? process.env.NEXT_PUBLIC_NEW_NFT : process.env.NEXT_PUBLIC_NFT
            }`}
          >
            <a className={classes.modalLink} target="_blank">
              {shortenAddress(
                nftDetail?.is_bridged
                  ? process.env.NEXT_PUBLIC_NEW_NFT
                  : process.env.NEXT_PUBLIC_NFT,
              )}
              <ExternalLink className={classes.linkIcon} />
            </a>
          </Link>
        </Row>
        <Row>
          <p>{t('NFTDetail:detail_image')}</p>
          <Link href={`${IPFS_NODE}/${source.videoHDSrc || source.videoSrc || source.previewSrc}`}>
            <a className={classes.modalLink} target="_blank">
              {t('NFTDetail:ipfs_link')}
              <ExternalLink className={classes.linkIcon} />
            </a>
          </Link>
        </Row>
        <Row>
          <p className={classes.pointer}>{t('NFTDetail:token_id')}</p>
          <p className={classes.pointer}>{nftDetail?.hex_nft_contract_id}</p>
        </Row>
        <Row>
          <p>{t('NFTDetail:standard')}</p>
          <p>{t('NFTDetail:standard_erc')}</p>
        </Row>
        <Row>
          <p>{t('NFTDetail:blockchain')}</p>
          <p>{t('NFTDetail:polygon')}</p>
        </Row>
      </>
    );
  };

  return (
    <div className={`${classes.NFTDetailsCard} ${className}`}>
      <Modal
        mobileBottom
        visible={isOpen}
        onStateChange={({ isOpen }) => setIsOpen(isOpen)}
        maskClosable={true}
      >
        <Root>
          <Container>
            <h1 className={classes.headerModal}>{t('NFTDetail:token_info')}</h1>
            {modalContent()}
          </Container>
        </Root>
      </Modal>

      <div className={classes.innerContainer}>
        <header className={classes.header}>
          <h1 className={classes.nftTitle}>{JSON.parse(nftDetail?.title)[lang] || 'Untitled'}</h1>
          <div className={classes.shareButton}>
            <ShareButton label={t('common:share')} handleClick={handleClick} nftVariant />
            {showShareMenu && (
              <ShareMenu
                className={classes.shareMenu}
                closeMenu={() => setShowShareMenu(false)}
                title={`${t('Share:nft')} ${JSON.parse(nftDetail?.title)[lang] || 'Untitled'}`}
              />
            )}
          </div>
        </header>
        <div className={`${classes.row} ${classes.metaRow}`}>
          {!isAirdrop && (
            <Fragment>
              <p className={classes.artwork}>
                {t('common:artwork')} {edition}
              </p>
              <p className={classes.gradient}>{t('common:first_collection')}</p>
            </Fragment>
          )}
          {isAirdrop && (
            <Fragment>
              <IconExclusive className={classes.iconExclusive} />
              <p className={classes.artworkExclusive}>{t('common:exclusive')}</p>
            </Fragment>
          )}
        </div>
        <div className={classes.description}>
          {descriptionParagraphs?.map((paragraph, index) => {
            const isLast = index + 1 === descriptionParagraphs?.length;
            return (
              <div key={index}>
                <Markdown>{paragraph}</Markdown>
                {isLast && ' '}
                {isLast && descOverflooded && (
                  <button
                    className={classes.seeMore}
                    onClick={() => showFullDescription(!isAllDesc)}
                  >
                    {isAllDesc ? t('NFTDetail:read_less') : t('NFTDetail:read_more')}
                  </button>
                )}
              </div>
            );
          })}
        </div>
        <div className={`${classes.row} ${classes.metadata}`}>
          <div className={`${classes.column} ${sales ? classes.three : classes.two}`}>
            <p className={classes.lightLabel}>{t('Lot:created_by')}:</p>
            <Link href={`/user/${nftDetail?.creator}`}>
              <a className={classes.innerRow}>
                <div className={classes.avatar}>
                  <Avatar
                    className={classes.identicon}
                    seed={nftDetail?.creator}
                    personalizedAvatar={creatorAvatar}
                    requireVerification
                  />
                </div>
                {width > 300 && (
                  <p className={classes.creatorName}>
                    {getUser(nftDetail?.creator).name || shortenAddress(nftDetail?.creator)}
                  </p>
                )}
              </a>
            </Link>
          </div>
          <div className={`${classes.column} ${sales ? classes.three : classes.two}`}>
            <div className={classes.collectorsListTileContainer}>
              <p className={classes.lightLabel}>{t('Lot:collected_by')}</p>
              <div className={classes.innerRow}>
                <div style={{ display: 'contents' }}>
                  <Link href={`/user/${nftDetail?.owner?.publicAddress}`}>
                    <a className={classes.avatar}>
                      <Avatar
                        className={classes.identicon}
                        user={realOwner}
                        seed={realOwner?.publicAddress}
                        requireVerification
                      />
                    </a>
                  </Link>
                  <Link href={`/user/${realOwner?.publicAddress}`}>
                    <a className={classes.collectorAddress}>
                      {realOwner?.publicName ||
                        getUser(realOwner?.publicAddress).name ||
                        shortenAddress(realOwner?.publicAddress)}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className={`${classes.column} ${sales ? classes.three : classes.two}`}>
            <p className={classes.lightLabel}>{t('NFTDetail:token_info')}</p>
            <div className={classes.innerRow}>
              {isMobile ? (
                <BottomSheet
                  header={header}
                  footer={'fotter'}
                  overlay={modalContent()}
                  isOpen={false}
                  withPaddings
                >
                  {width > 300 && (
                    <p className={classes.tokenName}>
                      <PolygonIcon /> ID {nftDetail?.id} {t('common:and_more')}
                    </p>
                  )}
                </BottomSheet>
              ) : (
                width > 300 && (
                  <p onClick={() => setIsOpen(true)} className={classes.tokenName}>
                    <PolygonIcon /> ID {nftDetail?.id} {t('common:and_more')}
                  </p>
                )
              )}
            </div>
          </div>

          {/* {!isAirdrop && ( */}
          <div className={`${classes.column} ${sales ? classes.three : classes.two}`}>
            <p className={classes.lightLabel}>{t('NFTDetail:collection')}</p>
            <div className={classes.innerRow}>
              <div className={classes.collectorByName}>
                {width > 300 && (
                  <>
                    {!isAirdrop ? (
                      <Link
                        href={
                          !isAirdrop
                            ? `/collections/${encodeURIComponent(nftDetail?.collection?.titleUrl)}`
                            : '#'
                        }
                      >
                        <a className={classes.creatorName}>{nftDetail?.collection?.title}</a>
                      </Link>
                    ) : (
                      <p className={classes.creatorName}>{nftDetail?.collection?.title}</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
      {!isAirdrop && (
        <div className={classes.bottomBar}>
          <BidBar
            buy={buy}
            nftDetail={nftDetail}
            claim={claim}
            bidStatus={bidStatus}
            claimStatus={claimStatus}
            euroPrice={euroPrice}
            lotData={nftDetail?.active_lot || lotData}
            lastSoldPrice={nftDetail?.last_sold_price || 0}
            bidData={bidData}
            hasLoaded={hasLoaded}
            refreshData={refreshData}
            copiesUserOwned={copiesUserOwned}
            setShowPaymentPanel={setShowPaymentPanel}
          />
        </div>
      )}
    </div>
  );
}

const Header = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.textColor};
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    ${theme.device.tabletS} {
      padding: 24px;
      padding-bottom: 16px;
    }
    span {
      margin: 0;
    }
  `}
`;

const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${theme.device.tabletS} {
      width: 422px;
      max-width: 422px;
      padding: 0;
      max-height: 720px;
      height: 100%;
    }
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    padding: 16px;
    ${theme.device.tabletS} {
      padding: 32px 32px 16px 32px;
    }
  `}
`;

const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
      margin-bottom: 0 !important;
    }

    p:nth-child(1) {
      ${typo.overline_11}
      color: #B2B2B2;
      margin-bottom: 8px !important;
    }

    p:nth-child(2) {
      ${typo.uiText_M_Bold}
      color: white;
    }
    ${theme.device.tabletS} {
      margin-bottom: 16px;
      align-items: center;

      p:nth-child(1) {
        ${typo.uiText_M_Medium}
        margin-bottom: 0px !important;
      }

      p:nth-child(2) {
        ${typo.uiText_M_Bold}
        color: white;
      }

      flex-direction: row;
    }
  `}
`;
