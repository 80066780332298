// Next
import { useEffect, useRef } from 'react'
import Link from 'next/link';
// Components
import Avatar from '~/components/_ui-kit/Avatar/Avatar';
import { Dropdown } from '~/components/Icons';
import { SimpleMenuWrapper } from '~/components/Menus';
// Hooks, and utils
import { useLang } from '~/store/languageContext/hooks';
import { useUsers } from '~/store/userContext/hooks';
import { shortenAddress } from '~/utils';
// Styles
import classes from './AvatarDropdown.module.scss';
import { iLot, iLotNFT, iUserBlockchainNFT, iUserProfile } from '~/store/services/mito/mito.models';

interface Props {
  className?: string;
  setShowAvatarDropdown?: React.Dispatch<React.SetStateAction<boolean>>;
  showAvatarDropdown?: boolean;
  lot?: Partial<iLot>;
  userData?: iUserProfile;
  userNft?: iUserBlockchainNFT;
  artistFirstCollection: boolean;
  personalizedAvatar?: string;
}

export default function AvatarDropdown({
  className,
  userNft,
  setShowAvatarDropdown,
  userData,
  lot,
  artistFirstCollection,
  personalizedAvatar,
}: Props) {
  const { getUser } = useUsers();
  const { t } = useLang();
  const handleClose = () => setShowAvatarDropdown(false);
  const nft = lot?.nft || userNft;
  const creatorFull = nft?.creator || userNft?.creator;
  const wrapperRef = useRef(null);


  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = event => {
        if(ref.current && !ref.current.contains(event.target)) setShowAvatarDropdown(false)
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <SimpleMenuWrapper
      className={`${classes.AvatarDropdownMenu} ${className && className}`}
      requireListener={false}
    >
      <ul ref={wrapperRef}>
        <Dropdown className={classes.dropdownIcon} onClick={handleClose} />

        {((nft && lot?.owner) && !lot?.is_primary_sale || userData)
         || (userNft?.active_lot && !lot?.is_primary_sale) ? (
          <>
            <li className={classes.ulSmall}>
              <div className={classes.subTitle}>{t('Lot:collected_by')}</div>
              <Link href={`/user/${encodeURIComponent(lot?.owner?.publicAddress || userNft?.collector?.publicAddress || userData?.publicAddress)}`}>
                <div className={classes.avatarAddress}>
                  <Avatar
                    requireVerification
                    className={classes.identicon}
                    seed={ lot?.owner?.publicAddress || userNft?.collector?.publicAddress || userData?.publicAddress || userNft?.active_lot?.owner?.publicAddress }
                    user={ lot?.owner || userData || userNft?.active_lot?.owner }
                  />
                  { lot?.owner && <p>{lot?.owner?.publicName || shortenAddress(lot?.owner?.publicAddress)}</p> }
                  { userNft?.collector && <p>{userNft?.collector?.publicName || shortenAddress(userNft?.collector?.publicAddress)}</p> }
                  { userNft?.active_lot?.owner && <p>{ userNft?.active_lot?.owner?.publicName || shortenAddress(userNft?.active_lot?.owner?.publicAddress)}</p> }
                  { userData && <p>{ userData?.publicName || shortenAddress(userData?.publicAddress)}</p> }
                </div>
              </Link>
            </li>
            <span className={classes.divider}></span>
          </>
        ) : null}

        <li className={classes.ulSmall}>
          <div className={classes.subTitle}>{t('Lot:created_by')}</div>
          <Link href={`/user/${creatorFull}`}>
            <div className={classes.avatarAddress}>
              <Avatar
                requireVerification
                userAddress={ creatorFull }
                personalizedAvatar={ getUser(creatorFull).avatar }
                className={classes.identicon}
                 seed={creatorFull || 'Anonymous'}
              />
              <p>{ getUser(creatorFull).name || shortenAddress(creatorFull) }</p>
            </div>
          </Link>
        </li>
      </ul>
    </SimpleMenuWrapper>
  );
}
