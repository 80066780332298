import React from 'react';
import classes from './SelectionChipButton.module.scss';
import { IconSelectedChip } from '~/components/Icons';

interface Props {
  label: string;
  className?: string;
  active?: boolean;
  onClick?: any;
}

export default function SelectionChipButton({ onClick, active, label }: Props) {
  return (
    <button
      onClick={onClick}
      className={`${classes.Chip} ${active && classes.active}`}
    >
      <div className={classes.innerContainer}>
        {active && <IconSelectedChip className={classes.icon} />}
        <span className={classes.label}>{label}</span>
      </div>
    </button>
  );
}
