// React, next and web 3
import { FC, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import Link from 'next/link';
// Components
import styled from '@emotion/styled';
import Avatar from '~/components/_ui-kit/Avatar/Avatar';
import Chip from '~/components/Chip/Chip';
import { Dropdown } from '~/components/Icons';
import ReactPlayer from 'react-player/lazy';
import ImageWithFallback from '~/components/ImageWithFallback/ImageWithFallback';
import AvatarDropdown from '~/components/Menus/AvatarDropdown/AvatarDropdown';
import classes from './NFTCard.module.scss';
import StateChip from '~/components/StateChip/StateChip';
// Hooks
import useLotPrice from '~/hooks/useLotPrice';
import useLotInfo from '~/hooks/useLotInfo';
import { useLang } from '~/store/languageContext/hooks';
import { useFiatConversion } from '~/store/fiatConversionContext/hooks';
import useMediaDevice from '~/hooks/useMediaDevice';
// Utils and styles
import { localePrice } from '~/utils';
import { useInView } from 'react-intersection-observer';

// Migration
import { iLot, iUserBlockchainNFT } from '~/store/services/mito/mito.models';
import { generateStatics, StaticsSizes, StaticTypes } from './NFTCard.adapter';
import { parseUserForStatics } from '../ArtistNftCard/ArtistNFTCard.adapter';

interface iNFTCard {
  lot?: iLot;
  userNft?: iUserBlockchainNFT;
  className: string;
  hideSoldOuts?: boolean;
}

const NFTCard: FC<Partial<iNFTCard>> = ({ lot, hideSoldOuts, userNft }: iNFTCard) => {
  const { account, active } = useWeb3React();
  const { t, lang } = useLang();
  const { ref, inView } = useInView({ threshold: 0.3 });
  const { formatFiat } = useFiatConversion();
  const lotInfo = useLotInfo({ lot, userNft });
  const { nft, labels, edition } = lotInfo;
  const { fiatPrice, maticPrice } = useLotPrice({ lot, userNft });

  const isMobile = useMediaDevice('mobile');

  const [showAvatarDropdown, setShowAvatarDropdown] = useState<boolean>(false);
  const [isHover, setisHover] = useState<boolean>(false);
  if (!lot?.nft?.title && !userNft?.title) return null;
  const title = lot?.nft?.title || userNft?.title;
  const isVideoExtension =
    nft?.content_type === StaticTypes.VIDEO || nft?.content_type === StaticTypes.VIDEO;

  const Button = styled.button`
    transform: ${() => (showAvatarDropdown ? `rotate(-180deg)` : `rotate(0deg)`)};
  `;

  const handleDropdown = (e: any) => {
    e.preventDefault();
    setShowAvatarDropdown(!showAvatarDropdown);
  };

  const staticSrc = generateStatics(nft || parseUserForStatics(userNft), StaticsSizes.THUMB);
  if (hideSoldOuts && t('Lot:sold_out') === labels.chip) return null;

  const isSecondary = lot?.is_primary_sale === false;
  const isAirdrop = userNft?.type === 'airdrop';
  const validUrl = () => {
    switch (true) {
      case !userNft?.active_lot && !lot && !isAirdrop:
        return `/nft-detail/${lot?.nft?.nft_contract_id || userNft?.id}`;
      case isAirdrop:
        return `/nft-detail/${userNft?.nft_contract_id || userNft?.original_nft_contract_id}`;
      case isSecondary:
        return `/nft-detail/${lot?.nft?.nft_contract_id || userNft?.id}`;
      default:
        return `/nft/${lot?.lot_id || userNft?.active_lot?.lot_id}`;
    }
  };

  return (
    <div
      className={classes.wrapper}
      onMouseEnter={() => setisHover(true)}
      onMouseLeave={() => setisHover(false)}
    >
      <Link href={validUrl()}>
        <a className={classes.linkContainer}>
          <div className={classes.NFTCard}>
            <div className={classes.topContainer} ref={ref}>
              {t('Lot:sold_out') === labels.chip || (!userNft && <StateChip lot={lot} />)}
              {!isVideoExtension && (
                <ImageWithFallback
                  src={staticSrc.primary}
                  fallbackSrc={staticSrc.secondary}
                  alt="nft"
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                />
              )}
              {isVideoExtension && (
                <ReactPlayer
                  url={staticSrc.primary}
                  className={classes.video}
                  width="100%"
                  height="100%"
                  playsinline
                  playing={inView && isHover}
                  autoPlay
                  loop
                  style={{ position: 'absolute', top: '0', borderRadius: '1.6rem 1.6rem 0 0' }}
                  muted
                  config={{
                    file: {
                      attributes: { disableRemotePlayback: true },
                    },
                  }}
                />
              )}
            </div>
            <div className={classes.avatarDropdown}>
              <Button onClick={(e: any) => handleDropdown(e)} className={classes.avatarButtonGroup}>
                <Avatar
                  className={classes.identicon}
                  seed={nft?.creator || userNft?.creator}
                  requireVerification={false}
                />
                <Dropdown className={classes.dropdownButton} />
              </Button>
            </div>
            <div className={classes.bottomContainer}>
              <div className={classes.chipContainer}>
                {(lot && lot?.nft?.collection?.is_first_collection) ||
                  (userNft?.active_lot && userNft?.collection?.is_first_collection && (
                    <Chip className={classes.collectionChip} text="">
                      <span>{t('common:first_collection')}</span>
                    </Chip>
                  ))}
                {isSecondary && edition && <Chip className={classes.chip} text={edition} />}
                {!isSecondary && active && account && labels.chip && (
                  <Chip className={classes.chip} text={labels.chip} />
                )}
              </div>
              <div className={`${classes.row} ${classes.top}`}>
                <p className={`${classes.innerRow}  ${classes.collection}`}>
                  {nft?.collection?.title || userNft?.collection?.title}
                </p>
                <h3 className={`${classes.innerRow} ${classes.title}`}>
                  {JSON.parse(title)[lang]}
                </h3>
              </div>
              <div className={`${classes.row} ${classes.bottom}`}>
                {labels.price && (
                  <div className={classes.priceContainer}>
                    {isSecondary && !userNft && (
                      <p className={`${classes.innerRow} ${classes.subHeading}`}>
                        {t('Lot:buy_for')}
                      </p>
                    )}
                    {!isSecondary && (
                      <p className={`${classes.innerRow} ${classes.subHeading}`}>{labels.price}</p>
                    )}
                    <p className={`${classes.innerRow} ${classes.price}`}>
                      {maticPrice && localePrice(maticPrice.toString(), 0, 0, lang)} MATIC{' '}
                      {formatFiat(localePrice(fiatPrice.toString(), 2, null, lang))}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <span className={classes.overlay} />
        </a>
      </Link>
      {showAvatarDropdown && (
        <AvatarDropdown
          lot={lot}
          userNft={userNft}
          artistFirstCollection={true}
          setShowAvatarDropdown={setShowAvatarDropdown}
          showAvatarDropdown={showAvatarDropdown}
        />
      )}
    </div>
  );
};

export default NFTCard;
