import React from 'react';
import classes from './Line.module.scss';

interface Props {
  className?: string;
}

function Line(props: Props) {
  return (
    <div className={`${classes.outerContainer}  ${props.className}`}>
      <div className={`${classes.ColorLine}`} />
    </div>
  );
}

export default React.memo(Line);
