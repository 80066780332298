import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import useMediaDevice from '~/hooks/useMediaDevice';
import CloseButton from '../CloseButton';

export interface ModalProps extends AntModalProps {
  onStateChange?: (state: { isOpen: boolean }) => void;
  isOpen?: boolean;
  mobileFull?: boolean;
  hideCloseButton?: boolean;
  mobileBottom?: boolean;
}

const Modal = ({
  children,
  isOpen,
  hideCloseButton,
  mobileFull,
  mobileBottom,
  onStateChange,
  ...antdModalProps
}: PropsWithChildren<ModalProps>) => {
  const [_isOpen, setIsOpen] = useState(isOpen);
  const isMobile = useMediaDevice('mobile');

  const closeModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);
    if (onStateChange) {
      onStateChange({ isOpen: false });
    }
  };

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  return (
    <AntModal
      onCancel={closeModal}
      maskClosable
      width={isMobile ? '100%' : 'auto'}
      centered
      closable={!hideCloseButton}
      destroyOnClose
      closeIcon={
        !hideCloseButton && (
          <CloseButtonContainer>
            <CloseButton size={isMobile ? 'small' : 'large'} />
          </CloseButtonContainer>
        )
      }
      visible={isOpen}
      {...antdModalProps}
      footer={null}
    >
      <AntModalOverrides mobileFull={mobileFull} mobileBottom={mobileBottom} />
      {children}
    </AntModal>
  );
};

export default Modal;

/** Styled components */

interface AntModalOverridesProps {
  mobileFull?: ModalProps['mobileFull'];
  mobileBottom?: ModalProps['mobileBottom'];
}
const AntModalOverrides = createGlobalStyle<AntModalOverridesProps>`
  ${({ theme, mobileFull, mobileBottom }) => css`
    .ant-modal-close {
      z-index: 99999;
    }
    .ant-modal {
      max-width: calc(100vw - 24px);
      font-feature-settings: 'tnum' off;

      &-content {
        box-shadow: 0px 4px 39px 11px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        background: ${theme.colors.background2};
      }

      &-body {
        border-radius: 24px;
        padding: 0;
      }

      &-close-x {
        width: auto;
        height: auto;
      }

      &-mask {
        background: transparent;
      }

      ${mobileFull &&
      css`
        ${theme.device.mobile} {
          max-width: none;
          height: 100%;
          margin: 0px;
          padding: 0px;
          &-content {
            height: 100%;
            border-radius: 0px;
          }
          &-body {
            height: 100%;
            border-radius: 0px;
          }
        }
      `}

      ${mobileBottom &&
        css`
          ${theme.device.mobile} {
            max-width: none;
            margin: 0px;
            padding: 0px;
            vertical-align: bottom !important;
            &-content {
              height: 100%;
              border-top-left-radius: 24px;
              border-top-right-radius: 24px;
            }
            &-body {
              border-radius: 0px;
            }
          }
        `}
    }
  `}
`;

const CloseButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin-top: 8px;
    margin-right: 8px;
    ${theme.device.tabletS} {
      margin-top: 24px;
      margin-right: 24px;
    }
  `}
`;
