import { useEffect, useState } from 'react';
import { useStore } from '~/store/store';
import { iLot, iNFTDetails, iUserBlockchainNFT } from '~/store/services/mito/mito.models';
import { getPrice } from '~/ether-utils';
import { compareBids } from '~/utils';
import useLotInfo from './useLotInfo';
import _orderBy from 'lodash/orderBy';
import { EAuctionTypes } from '~/store/services/mito/mito.enums';

interface Props {
  lot?: Partial<iLot>;
  userNft?: iUserBlockchainNFT;
  directSaleNft?: iNFTDetails;
}

export default function useLotPrice({ lot, userNft, directSaleNft }: Props) {
  const lotInfo = useLotInfo({ lot }) || null;
  const type = lotInfo?.auctionType;
  const { state } = useStore();
  const [fiatPrice, setFiatPrice] = useState(0);
  const [maticPrice, setMaticPrice] = useState(0);

  useEffect(() => {
    if (type === EAuctionTypes.FIRST_PRICE || type === EAuctionTypes.FIRST_PRICE_V2) {
      const matic = getPrice(lot?.current_price || lot?.price);
      const fiat = matic * state.conversionRate;
      setFiatPrice(fiat);
      setMaticPrice(matic);
    }
    if (type === EAuctionTypes.TIMED_EDITION || type === EAuctionTypes.TIMED_EDITION_V2
        || type === EAuctionTypes.TIMED_EDITION_BUY_LIMIT || type === EAuctionTypes.TIMED_EDITION_BUY_LIMIT_V2
        || type === EAuctionTypes.TIMED_EDITION_MOONPAY) {
      const matic = getPrice(lot?.current_price || lot?.price);
      const fiat = matic * state.conversionRate;
      setFiatPrice(fiat);
      setMaticPrice(matic);
    }

    if (type === EAuctionTypes.DIRECT_SALE_V2) {
      const matic = getPrice(lot?.current_price || lot?.price);
      const fiat = matic * state.conversionRate;
      setFiatPrice(fiat);
      setMaticPrice(matic);
    }

    if( userNft ){
      const matic = userNft?.active_lot ? getPrice(userNft?.active_lot?.price) : getPrice(userNft?.last_sold_price);
      const fiat = matic * state.conversionRate;
      setFiatPrice(fiat);
      setMaticPrice(matic);
    }

    if( directSaleNft ){
      const matic = getPrice(directSaleNft?.active_lot?.price || directSaleNft?.last_sold_price);
      const fiat = matic * state.conversionRate;
      setFiatPrice(fiat);
      setMaticPrice(matic);
    }
  }, [lot, state.conversionRate]);

  return { fiatPrice, maticPrice };
}
